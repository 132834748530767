import React from 'react'
import classNames from 'classnames'

const Select = ({
  className,
  options = [],
  value = '',
  onChange = () => {},
  type = String,
  uppercase = true,
  prepend = '',
}) => {
  const selectedOption = options.find((option) => option.value === value)
  const selectedLabel = (selectedOption && selectedOption.label) || ''
  const selectable = options.length > 1
  return (
    <div
      className={classNames(
        'select',
        uppercase && 'uppercase',
        selectable && 'selectable',
        className
      )}
    >
      <div className="label">{`${prepend}${selectedLabel}`}</div>
      <div className="select-chevron">
        <ChevronSvg />
      </div>
      <select
        value={value}
        onChange={(event) => {
          const index = event.target.options.selectedIndex
          const value = event.target.options[index].value
          onChange({ value: type(value), event: event })
        }}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

const ChevronSvg = () => (
  <svg
    width="16"
    height="10"
    viewBox="0 0 16 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5079 0.241292L0.166992 1.63946L7.66699 9.40796L15.167 1.63161L13.8261 0.241292L7.66699 6.62732L1.5079 0.241292Z"
      fill="white"
    />
  </svg>
)

export default Select
