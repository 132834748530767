import React, { useState, useEffect } from 'react'
import { useStore } from 'src/state'
import Page from 'src/components/page'
import Link from 'src/components/link'
import Picture from 'src/components/picture'
import Section from 'src/components/section'
import RichText from 'src/components/rich-text'
import BoxButton from 'src/components/box-button'
import Button from 'src/components/button'
import Select from 'src/components/select'
import Slider from 'src/components/slider'
import AspectBox from 'src/components/aspect-box'
import { refreshProduct, addToCart } from 'src/api/shopify-client-api'

const ProductDetail = ({ pageContext }) => {
  const [hasFetched, setHasFetched] = useState(false)
  const [product, setProduct] = useState(pageContext.product)
  const { title, images, body_html, variants } = product
  const [variant, setVariant] = useState(variants[0])
  const [quantity, setQuantity] = useState(1)
  const {
    setCheckout,
    shouldShowCartOnAdd,
    showCart,
    setIsWorking,
    setFormOverlayId,
    showFormOverlay,
    setNotifyMeProductHandle,
    general: {
      productDetailBreadcrumbText,
      soldOutText,
      quantityText,
      addToCartText,
      notifyMeFormId,
      notifyMeText,
      variantDefaultTitle,
      preorderNowText,
    },
  } = useStore()
  const maxQuantity = 10 // Number(variant.inventory_quantity)
  const isSoldOut = variant.inventory_quantity < 1
  const isDefaultVariant = variant.title === variantDefaultTitle
  const shouldContinueSelling =
    isSoldOut && variant.inventory_policy === 'continue'

  useEffect(() => {
    async function fetchData() {
      const refreshedProduct = await refreshProduct(product)
      setProduct(refreshedProduct)
      setVariant(refreshedProduct.variants[0])
    }
    !hasFetched && fetchData()
    setHasFetched(true)
  }, [product, hasFetched])

  useEffect(() => {
    setQuantity(1)
  }, [variant])

  let quantityOptions =
    maxQuantity > 0
      ? Array(maxQuantity)
          .fill()
          .map((n, i) => ({
            value: i + 1,
            label: `${i + 1}`,
          }))
      : [
          {
            value: 1,
            label: '1',
          },
        ]

  if (shouldContinueSelling) {
    quantityOptions = Array(10)
      .fill()
      .map((n, i) => ({
        value: i + 1,
        label: `${i + 1}`,
      }))
  }
  return (
    <Page className="page-product-detail">
      <Slider className="product-slider" images={images}>
        {images.map((image) => (
          <AspectBox key={image.id} className="slider-image" x={324} y={450}>
            <Picture
              defaultWidth={750}
              image={image}
              fitWidth
              fitHeight
              cover
            />
          </AspectBox>
        ))}
      </Slider>
      <Section className="product-images">
        {images.map((image) => (
          <Picture
            className="product-image"
            key={image.id}
            image={image}
            fitWidth
            cover
            defaultWidth={1200}
          />
        ))}
      </Section>
      {product.isRefreshed && (
        <Section className="product-details">
          <div className="details-start">
            <Link to="/" className="breadcrumb">
              {productDetailBreadcrumbText}
            </Link>
            <h3 className="title size-big">{title}</h3>
            <h2 className="price section-title">{variant.prettyPrice}</h2>
            <RichText className="description">{body_html}</RichText>
          </div>
          <div className="details-end">
            <div className="product-options">
              <Select
                className="product-option type-quantity"
                value={quantity}
                type={Number}
                options={quantityOptions}
                onChange={({ value }) => setQuantity(value)}
                prepend={`${quantityText}: `}
              />
              {!isDefaultVariant &&
                product.options.map((option) => (
                  <Select
                    key={option.id}
                    className={`product-option type-${option.name}`}
                    value={variant[`option${option.position}`]}
                    onChange={({ value }) => {
                      const values = product.options.map((o) => {
                        let val = variant[`option${o.position}`]
                        if (o.position === option.position) val = value
                        return {
                          name: o.name,
                          position: o.position,
                          value: val,
                        }
                      })
                      const newVariant = product.variants.find((nv) => {
                        let matches = 0
                        for (const val of values) {
                          if (val.value === nv[`option${val.position}`])
                            matches++
                        }
                        return matches === values.length ? nv : null
                      })
                      if (newVariant) setVariant(newVariant)
                    }}
                    prepend={`${option.name}: `}
                    options={option.values.map((value) => ({
                      value: value,
                      label: value,
                    }))}
                  />
                ))}
            </div>
            {isSoldOut ? (
              isSoldOut && shouldContinueSelling ? (
                <BoxButton
                  className="add-cart-btn"
                  onClick={async () => {
                    setIsWorking(true)
                    const checkout = await addToCart(variant, quantity)
                    setCheckout(checkout)
                    shouldShowCartOnAdd && showCart()
                    setIsWorking(false)
                  }}
                >
                  {preorderNowText}
                </BoxButton>
              ) : (
                <>
                  <BoxButton className="sold-out-btn" disabled darker>
                    {soldOutText}
                  </BoxButton>
                  {!product.hasInventory && (
                    <Button
                      onClick={() => {
                        setFormOverlayId(notifyMeFormId)
                        setNotifyMeProductHandle(product.handle)
                        showFormOverlay()
                      }}
                      className="notify-me-btn"
                    >
                      <p className="action-text centered">{notifyMeText}</p>
                    </Button>
                  )}
                </>
              )
            ) : (
              <BoxButton
                className="add-cart-btn"
                onClick={async () => {
                  setIsWorking(true)
                  const checkout = await addToCart(variant, quantity)
                  setCheckout(checkout)
                  shouldShowCartOnAdd && showCart()
                  setIsWorking(false)
                }}
              >
                {product.metafields.addToCartText || addToCartText}
              </BoxButton>
            )}
          </div>
        </Section>
      )}
    </Page>
  )
}

export default ProductDetail
